import React from "react";
import styled from "styled-components";
import { ButtonChecks } from "../../images";


const CheckedWrapper = styled.div`
    display: none;
`;

const CardButtons = styled.label`
    cursor: pointer;
`;

const CheckedCheckbox = styled.input`
    display: none;
    &:checked + ${CardButtons} ${CheckedWrapper} {
        display: block;
      }

      &:checked + ${CardButtons}{
        border: solid 1px #186F3D;
      }
`
const CheckedCards = ({ id, checked, children, handleChange }) => {
    return (
        <>
            <CheckedCheckbox type="checkbox" name="card" id={id} checked={checked} onChange={handleChange}/>
            <CardButtons htmlFor={id} className="p-2 border border-[#E6E6E6] w-[57px] h-[42px] rounded sm:mr-6 flex items-center relative">
                <CheckedWrapper className="absolute top-[-3px] right-[-2px]">
                    <ButtonChecks alt="checks" />
                </CheckedWrapper>
                {children}
            </CardButtons>
        </>
    )
};


export default CheckedCards;