import { Chicken, Peak, PalmFruits, Ellipse } from "../images"
export const USER_INFO_ORDER = [
  {
    id: 1,
    name: "April Akpan",
    phoneNumber: "+125 000 2892",
    deliveryAddress: "471 East Beaver Creek Rd, ON L4B 1M7, Ontario, Canada",
    profilePhoto: Ellipse,
    isOpen: true,
    orderNo: "#374959",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Peak,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 53.38,
        qty: 23,
        weigh: "360g",
      },
      {
        id: 2,
        img: Peak,
        item: "Boneless Chicken Breasts With Rib Meat",
        price: 45.64,
        qty: 54,
        weigh: "per g",
      },
    ],
    stateOfOrder: "Pending",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 45.78,
    tip: 5.92,
    taxes: 62.82
  },
  {
    id: 2,
    name: "John Sunday",
    phoneNumber: "+153 346 7242",
    deliveryAddress: "471 West Wills Creek Rd, ON L4B 1M7, Ontario, New york",
    profilePhoto: Ellipse,
    isOpen: true,
    orderNo: "#239459",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Chicken,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 82.64,
        qty: 45,
        weigh: "360g",
      },
      {
        id: 2,
        img: Chicken,
        item: "Boneless Chicken Breasts With Rib Meat",
        price: 28.63,
        qty: 4,
        weigh: "per g",
      },
      {
        id: 3,
        img: PalmFruits,
        item: "Palm Fruit (Kernel)",
        price: 39.67,
        qty: 16,
        weigh: "per kg",
      },
    ],
    stateOfOrder: "Shipped",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 85.78,
    tip: 18.60,
    taxes: 68.38
  },
  {
    id: 3,
    name: "MaryAnn Fisher",
    phoneNumber: "+371 857 7433",
    deliveryAddress: "847 North Beaver Creek Rd, ON L4B 1M7, Ontario, Massachusetts",
    profilePhoto: Ellipse,
    isOpen: false,
    orderNo: "#397464",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Peak,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 34.66,
        qty: 14,
        weigh: "360g",
      },
      {
        id: 2,
        img: Chicken,
        item: "Boneless Chicken Breasts With Rib Meat",
        price: 44.52,
        qty: 5,
        weigh: "per g",
      },
      {
        id: 3,
        img: PalmFruits,
        item: "Palm Fruit (Kernel)",
        price: 86.63,
        qty: 34,
        weigh: "per kg",
      },
    ],
    stateOfOrder: "Delivered",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 40.78,
    tip: 2.56,
    taxes: 98.38
  },
  {
    id: 4,
    name: "Paul Walker",
    phoneNumber: "+833 834 3487",
    deliveryAddress: "23 South Beaver Creek Rd, ON L4B 1M7, Ontario, Chicago",
    profilePhoto: Ellipse,
    isOpen: false,
    orderNo: "#878349",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Peak,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 84.6,
        qty: 55,
        weigh: "360g",
      },
      {
        id: 2,
        img: Chicken,
        item: "Boneless Chicken Breasts With Rib Meat",
        price: 78.66,
        qty: 65,
        weigh: "per g",
      },
    ],
    stateOfOrder: "Pending",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 83.78,
    tip: 50.23,
    taxes: 48.37
  },
  {
    id: 5,
    name: "Yennefer Solomon",
    phoneNumber: "+94 947 7583",
    deliveryAddress: "38 North-west Beaver Creek Rd, ON L4B 1M7, Ontario, Cuba",
    profilePhoto: Ellipse,
    isOpen: true,
    orderNo: "#992347",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Peak,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 64.90,
        qty: 19,
        weigh: "360g",
      },
      {
        id: 2,
        img: Chicken,
        item: "Boneless Chicken Breasts With Rib Meat",
        price: 34.66,
        qty: 65,
        weigh: "per g",
      },
      {
        id: 3,
        img: PalmFruits,
        item: "Palm Fruit (Kernel)",
        price: 65.6,
        qty: 7,
        weigh: "per kg",
      },
    ],
    stateOfOrder: "Shipped",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 84.78,
    tip: 20.84,
    taxes: 34.92
  },
  {
    id: 6,
    name: "Spencer Wills",
    phoneNumber: "+498 983 4874",
    deliveryAddress: "851 South-East Beaver Creek Rd, ON L4B 1M7, Ontario, Florida",
    profilePhoto: Ellipse,
    isOpen: false,
    orderNo: "#83484",
    price: 12.68,
    items: [
      {
        id: 1,
        img: Peak,
        item: "Peak Milk Full Cream Powder Pouch",
        price: 35.63,
        qty: 5,
        weigh: "360g",
      },
      {
        id: 3,
        img: PalmFruits,
        item: "Palm Fruit (Kernel)",
        price: 98.64,
        qty: 10,
        weigh: "per kg",
      },
    ],
    stateOfOrder: "Pending",
    dateShipped: "Jun 22, 1pm - 2pm",
    delivery: 93.78,
    tip: 15.23,
    taxes: 55.93
  },
];
