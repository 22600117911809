import {
  SEO,
  PageLayout,
  ResetSuccesful
} from "../components";

function ResetPasswordSuccessPage() {

  return (
    <>
      <SEO
        url="/reset-password-success"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
        description=""
      />
      <PageLayout>
        <ResetSuccesful />
      </PageLayout>
    </>
  );
}

export default ResetPasswordSuccessPage;
