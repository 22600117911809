import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OutSideClick } from "../../helpers";
import Cookies from "js-cookie";
import {
  AfroshopLogo,
  Cart,
  Menu,
  Location,
  GreenCancel,
  UserAvatar,
  AvatarIcon,
  ChevronRight,
  AvatarGrey,
  LogoutIcon,
} from "../../images";
import {
  activeModal,
  logOutUser,
  guestLogOut,
  setUserLocation,
} from "../../redux/action";
import { Button } from "../shared";
import CartCheckout from "../cart-with-products";
import { getCartSize } from "../../helpers/cartFunctions";
import { LOCATION } from "../../data/menuItems";
import LocationForm from "../location-form";
import { SELECTED_LOCATION, removeTokenFromCookie } from "../../utils/constants";

const Navbar = () => {
  const [cartSize, setCartSize] = useState(0);
  const [open, setOpen] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
  const ref = useRef(null);
  const locationRef = useRef(null);
  const boxOutsideClick = OutSideClick(ref);
  const locationClickOutside = OutSideClick(locationRef);
  const cart = useSelector((state) => state.cart);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const getActiveModalName = useSelector((state) => state.getActiveModalName);
  const user = useSelector((state) => state.user);
  const locationValue = useSelector((state) => state.location);
  const [location, setLocation] = useState(
    Cookies.get(SELECTED_LOCATION) || "940251"
  );
  const [openLocationForm, setOpenLocationForm] = useState(false);

  const dispatch = useDispatch();
  const handleShowCart = () => setShowCart(true);

  const handleHideCart = () => {
    document
      .querySelector(".cart-checkout-container")
      .classList.add("slide-out");
    setTimeout(() => {
      setShowCart(false);
    }, 100);
  };

  useEffect(() => {
    if (boxOutsideClick && !openLocationForm) {
      setOpen(false);
    }
  }, [boxOutsideClick, openLocationForm]);

  useEffect(() => {
    if (locationClickOutside) {
      setOpenLocationDropdown(false);
    }
  }, [locationClickOutside]);

  useEffect(() => {
    setCartSize(getCartSize(cart));
  }, [cart]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!event.target.closest(".cart-checkout-container")) {
        setShowCart(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleLoginClick = () => {
    dispatch(activeModal("login"));
    setOpen(false);
  };

  const handlesignUp = () => {
    dispatch(activeModal("signUp"));
    setOpen(false);
  };

  const handleProfile = () => {
    if (getActiveModalName === "loginProfile") {
      dispatch(activeModal(""));
    } else {
      dispatch(activeModal("loginProfile"));
    }
  };

  const handleLocationClick = (location) => {
    dispatch(setUserLocation({ location: location }));
    setLocation(location);
    Cookies.set(SELECTED_LOCATION, location, { expires: 365 });
  };

  const handleUserLogOut = () => {
    dispatch(logOutUser());
    removeTokenFromCookie()
  };
  useEffect(() => {
    setLocation(locationValue);
  }, [locationValue]);

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleMenuClick = () => {
    setOpen(open => !open)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 766) {
        if (getActiveModalName === "loginProfile") {
          dispatch(activeModal(""));
          setOpen(true)
        }   
        if (openLocationDropdown){
          setOpenLocationForm(true)
          setOpenLocationDropdown(false)
        }
      }
      else {
        if (open) {
          dispatch(activeModal("loginProfile"));
          setOpen(false)
        }
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getActiveModalName, openLocationDropdown])

  return (
    <div className="relative">
      {showCart && (
        <div className="fixed inset-0 flex justify-end bg-blackTransparent bg-opacity-10 z-50 ">
          <div className="overflow-y-scroll cart-checkout-this">
            <div className="cart-checkout-container slide-in">
              <CartCheckout className="" handleHideCart={handleHideCart} />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-between content-start items-center py-3 lg:py-6 lg:px-20 px-6 text-[13px] text-[#333333] leading-6 font-normal bg-[#FFFFFF]">
        <div>
          <Link to="/">
            <AfroshopLogo />
          </Link>
        </div>
        <div className="flex flex-row justify-end items-center gap-8 lg:gap-10 md:gap-8 w-full">
          {isAuthenticated ? (
            <>
              <div className="relative" onClick={handleShowCart}>
                <Cart className="cursor-pointer" />
                {cartSize !== 0 && (
                  <span className="absolute bottom-3 left-3 text-white bg-[#186F3D] inline-flex items-center justify-center w-5 h-5 rounded-full">
                    {cartSize}
                  </span>
                )}
              </div>
              <AvatarIcon
                className="hidden lg:flex md:flex cursor-pointer"
                onClick={handleProfile}
              />

              <div
                className="relative h-min cursor-pointer hidden lg:flex md:flex flex-col"
                ref={locationRef}
                onClick={() => setOpenLocationDropdown(!openLocationDropdown)}
              >
                <div className=" hidden lg:flex md:flex items-center gap-1">
                  <Location />
                  <p className="text-[#186F3D] items-center text-center">
                    {location}, CA
                  </p>
                </div>
                <p className="hidden lg:flex md:flex text-[10px] text-[#007AFF] text-center underline underline-offset-1">
                  Change Location
                </p>

                {openLocationDropdown && (
                  <ul className="bg-white absolute top-full right-0 min-w-[327px] w-full shadow-lg rounded z-[10] max-h-[192px] overflow-auto custom-scrollbar">
                    {LOCATION.map((el) => (
                      <li
                        key={el.id}
                        className="text-base border-b last:border-none py-2.5 px-3 border-[#fafafa] bg-white rounded cursor-pointer hover:bg-[#F2F2F2] hover:text-[#186F3D]"
                        onClick={() => {
                          handleLocationClick(el.value);
                          setOpenLocationDropdown(false);
                        }}
                      >
                        {el.value}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-row justify-end items-center gap-8 lg:gap-10 md:gap-8 w-full">
              <p className="hidden lg:flex md:flex">Sell on Afroshop</p>
              <p
                className="hidden lg:flex md:flex cursor-pointer"
                onClick={handleLoginClick}
              >
                Log in
              </p>
              <Button className="hidden md:flex lg:flex" onClick={handlesignUp}>
                Sign Up
              </Button>
              <div className="relative cursor-pointer" onClick={handleShowCart}>
                <Cart />
                {cartSize !== 0 && (
                  <span className="absolute bottom-3 left-3 text-white bg-[#186F3D] inline-flex items-center justify-center w-5 h-5 rounded-full">
                    {cartSize}
                  </span>
                )}
              </div>
              <div
                className="relative h-min cursor-pointer hidden lg:flex md:flex flex-col"
                ref={locationRef}
                onClick={() => setOpenLocationDropdown(!openLocationDropdown)}
              >
                <div className=" hidden lg:flex md:flex items-center gap-1">
                  <Location />
                  <p className="text-[#186F3D] items-center text-center">
                    {location}, CA
                  </p>
                </div>
                <p className="hidden lg:flex md:flex text-[10px] text-[#007AFF] text-center underline underline-offset-1">
                  Change Location
                </p>

                {openLocationDropdown && (
                  <ul className="bg-white absolute top-full right-0 min-w-[327px] w-full shadow-lg rounded z-[10] max-h-[192px] overflow-auto">
                    {LOCATION.map((el) => (
                      <li
                        key={el.id}
                        className="text-base border-b last:border-none py-2.5 px-3 border-[#fafafa] bg-white rounded cursor-pointer hover:bg-[#F2F2F2] hover:text-[#186F3D]"
                        onClick={() => {
                          handleLocationClick(el.value);
                          setOpenLocationDropdown(false);
                        }}
                      >
                        {el.value}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}

          {/* For Mobile */}
          <Menu
            className="flex lg:hidden md:hidden cursor-pointer"
            onClick={handleMenuClick}
          />
          {open && (
            <div
              className={`transform top-0 right-0 fixed overflow-auto ease-in-out transition-all duration-300 backdrop-sepia-0 bg-blackTransparent lg:hidden h-full w-full z-[1000] ${open ? "translate-x-0" : "translate-x-0"
                }`}
            >
              <aside
                ref={ref}
                className="flex flex-col gap-4 slide-in bg-white z-100 fixed right-0 top-0 px-6 py-8 h-full lg:w-[93%] md:w-[94%] sm:w-[92%] w-[87%]"
              >
                <GreenCancel
                  className="ml-auto cursor-pointer"
                  onClick={handleCloseModal}
                />
                <div
                  className="ml-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenLocationForm(true);
                  }}
                >
                  <div className="text-[#186F3D] flex flex-row gap-1 h-6">
                    <Location className="self-center" />
                    <p>{location}, CA</p>
                  </div>
                  <p className="text-[10px] text-[#007AFF] text-center underline underline-offset-1">
                    Change Location
                  </p>
                </div>

                {isAuthenticated ? (
                  <>
                    <div className="flex flex-col items-center gap-4 my-3">
                      <img
                        className="w-[50px] md:w-[100px] md:h-[100px] h-[50px] cursor-pointer object-contain rounded-[50%]"
                        src={user?.avatarUrl || UserAvatar}
                        alt="userImage"
                      />
                      <h6 className="font-bold text-base">
                        {" "}
                        {user?.firstName
                          ? `${user?.firstName} ${user?.lastName}`
                          : "Guest User"}
                      </h6>
                    </div>
                    <hr className=" bg-[#E6E6E6] h-[1px] w-full" />
                    <Link to={"/user-info"} className="w-full" onClick={handleCloseModal}>
                      <div className="flex justify-between gap-7">
                        <div className="flex gap-6">
                          <img src={AvatarGrey} alt="avatar" />
                          <p className="text-[#333333] text-base">
                            Manage Account
                          </p>
                        </div>
                        <img src={ChevronRight} alt="RightIcon" />
                      </div>
                    </Link>
                    <div
                      className="flex justify-between w-full h-6"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleUserLogOut();
                        dispatch(activeModal(""));
                        dispatch(guestLogOut());
                      }}
                    >
                      <button className="text-[#FF3B30] font-normal text-base cursor-pointer">
                        Logout
                      </button>
                      <img src={LogoutIcon} alt="LogoutIcon" />
                    </div>

                    <hr className="bg-[#E6E6E6] h-[1px] w-full" />
                  </>
                ) : (
                  <>
                    <Button className="w-full" onClick={handlesignUp}>
                      Sign Up
                    </Button>
                    <Button
                      className="w-full"
                      variant="transparent"
                      onClick={handleLoginClick}
                    >
                      {" "}
                      Log In
                    </Button>
                  </>
                )}

                <button className="text-base bg-white py-[10px]">
                  Sell on Afroshop
                </button>
              </aside>
            </div>
          )}
          {openLocationForm && (
            <LocationForm
              location={location}
              handleLocationClick={handleLocationClick}
              setOpenLocationForm={setOpenLocationForm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
