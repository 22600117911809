export const COOKIE_PREFERENCES = [
    {
        name:"Functional Cookies",
        details:"These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you."
    },
    {
        name:"Session Cookies",
        details:"These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you."        
    },
    {
        name:"Performance and Analytic Cookies",
        details:"These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you."
    }
]