import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../../shared'
import { AfroshopLogo } from '../../../images'
import { activeModal } from '../../../redux/action'
import { cancelIcon, ExpandFilterIcon, ExpandedFilterIcon } from '../../../images'
import { COOKIE_PREFERENCES } from '../../../data'

const ManageCookies = () => {
    const dispatch = useDispatch();
    const [expanded, setexpanded] = useState(false);
    const [selectAll, setSelectAll] = useState(false)

    const handleExpand = (index) =>{
      if (expanded === index) {
        return setexpanded(null);
      }
      setexpanded(index);
    }

    const handleTogglePref = () => setSelectAll(!selectAll);      
  

  return (
    <div className='fixed inset-0 bg-blackTransparent bg-opacity-10 z-10 flex justify-center items-center'>
      <div className='lg:w-[585px] w-[95%] md:py-[32px] lg:h-full overflow-y-auto overflow-hidden py-7 px-5 bg-[#FFFFFF] rounded-lg flex flex-col gap-6 md:gap-[40px]'>
         
          <img src={cancelIcon} alt="cancelIcon" className="ml-[95%] w-6 h-6" onClick={()=>dispatch(activeModal("cookiePopup"))}/>
                 
        <div className="text-[#333333] flex flex-col gap-5">
        <AfroshopLogo />
          <p className='text-xs lg:text-base'>
          Welcome to Afroshop! We use cookies to improve your shopping & delivery experience. By using this website, you agree to our use of cookies in accordance with our{" "}
           <a href="/terms-and-conditions" className="underline text-[#186F3D] text-xs lg:text-base">
               Privacy Policy
            </a>
          </p>

          <p className='text-base'><b>Manager Consent Preferences</b></p>

          <Button outline="green" variant="tertiary" className="w-[211px] h-[40px]" onClick={handleTogglePref}>
            Allow All Cookies
          </Button>
        </div>

        <div className='w-full max-h-[156px] lg:w-[540px] p-3 lg:p-6 border border-solid border-[#E6E6E6] rounded flex flex-col gap-3'>
           <div className='flex justify-between items-center'>
            <div className= "flex gap-3 items-center cursor-pointer" onClick= {()=>handleExpand(3)}>
                {expanded === 3 ? <ExpandedFilterIcon/> : <ExpandFilterIcon/>}
                <p className='text-xs lg:text-base'>Delivery Address Cookies</p>                 
            </div> 
                
            <p className='text-[#186F3D] text-xs lg:text-base'>Always Active</p>    
            </div>

             {expanded === 3 ? <p className='text-xs lg:text-base'>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you.</p> : null}                             
        </div>       

        {COOKIE_PREFERENCES.map((item, index)=>{
            return (
                <div className='w-full lg:w-[540px] p-3 lg:p-6 border border-solid border-[#E6E6E6] rounded flex flex-col gap-2'>
                   <div className='flex justify-between items-center'>
                   <div className= "flex gap-3 items-center cursor-pointer" onClick= {()=>handleExpand(index)} key={index}>
                        {expanded === index ? <ExpandedFilterIcon/> : <ExpandFilterIcon/>}
                        <p className='text-xs lg:text-base'>{item.name}</p>                       
                    </div> 

                    <div className='h-6'>
                        <label className="relative inline-flex items-center mb-5 cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={selectAll ? true : null}/>
                        <div className= "w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#186F3D]"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900" />                       
                        </label>
                    </div>
                    </div>
                    {expanded === index ? <p className='text-xs lg:text-base'>{item.details}</p> : null}
                </div> 
            )
        })}
        <Button className="w-[235px] " onClick={()=>dispatch(activeModal("cookiePopup"))}>Confirm My Choices</Button>
      </div>
    </div>
  )
}

export default ManageCookies