import React, { useState, useEffect } from "react";
import { locate, IconDown } from "../../../images";
import { PropTypes } from "prop-types";
import { Button } from "../../shared";
import { useForm } from "react-hook-form";
import InputComponent from "../../shared/inputComponent";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { activeModal, setUserLocation } from "../../../redux/action";
import { LOCATION } from "../../../data";
import { SELECTED_LOCATION } from "../../../utils/constants";

const StyledList = styled.ul`
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: display 0.3s, opacity 0.3s;
  height: 150px;
  overflow-y: auto;
`;

const StyledListItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    color: #186f3d;
  }
`;
const Placeholder = styled.div`
  padding: 0.75rem;
  border: 1px solid #186f3d;
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  cursor: pointer;
`;
function DeliveryAvailabilty() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherRegion, setIsOtherRegion] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const savedCity = Cookies.get(SELECTED_LOCATION);
    if (savedCity) {
      setSelectedValue(savedCity);
    }
  }, []);

  const handleSelectChange = (event) => {
    const newCity = event.target.id;
    setSelectedValue(newCity);
    setIsOpen(false);
  };

  const handleCityChange = (event, bool) => {
    handleSelectChange(event);
    setIsOpen(bool);
  };

  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const toggleOtherRegion = () => {
    setIsOtherRegion(true);
  };

  const handleSubmitDelivery = () => {
    Cookies.set(SELECTED_LOCATION, selectedValue, { expires: 365 });
    dispatch(activeModal(""));
  };

  const handleSubmitDeliveryOtherLocation = (data) => {
    setSelectedValue(data.City);
    Cookies.set(SELECTED_LOCATION, data.City, { expires: 365 });
    dispatch(activeModal("noDelivery"));
  };

  return (
    <div className=" flex items-center justify-center h-screen fixed inset-0 bg-blackTransparent bg-opacity-10 rounded-[4px] z-50">
      <div className="flex items-center justify-center my-[50px] w-[400px] gap-4 md:w-[800px] bg-white px-4 mx-4 rounded-[4px]">
        <div>
          <div className="mx-auto mb-[30px]">
            <img src={locate} className="mx-auto" alt="location pin" />
          </div>
          <div className="text-center w-full md:w-[327px]">
            <h1 className="my-4 text-green text-[20px] font-[700]">
              Hi there!
            </h1>

            <div className="mb-2 leading-6 text-[#333333] text-[16px]">
              We currently offer delivery within the Greater Toronto Area (GTA)
              only. Please select your specific city within the GTA.
            </div>
            {isOtherRegion ? (
              <div>
                <p className="text-left text-[#B3B3B3] text-[13px] py-2">
                  City
                </p>
                <form
                  onSubmit={handleSubmit(handleSubmitDeliveryOtherLocation)}
                >
                  <InputComponent
                    placeholder="Enter City"
                    fieldName="City"
                    compoundValidation={true}
                    register={register}
                    errors={errors}
                    required
                    title={"city"}
                    requiredMessage={"Enter city"}
                    name={"City"}
                    type={"text"}
                  />

                  <Button
                    type="submit"
                    variant="primary"
                    outline="green"
                    className="w-[100%] h-[50px] my-[20px]"
                  >
                    Submit
                  </Button>
                </form>
                          
              </div>
            ) : (
              <div>
                <p className="text-left text-[#B3B3B3] text-[13px] py-2">
                  City
                </p>
                <form onSubmit={handleSubmit(handleSubmitDelivery)}>
                  <div className="relative">
                    <div className="mb-4 text-start">
                      <div onClick={toggleList}>
                        <select
                          value={selectedValue}
                          className="absolute -z-10 opacity-0"
                        >
                          {LOCATION.map((location, index) => {
                            return (
                              <option key={index} value={location.value}>
                                {location.value}
                              </option>
                            );
                          })}
                          <option value="Other">Other</option>
                        </select>
                        <Placeholder>
                          {selectedValue ? selectedValue : "Select an option"}
                        </Placeholder>
                      </div>

                      <StyledList open={isOpen}>
                        {LOCATION.map(({ value }, index) => {
                          return (
                            <StyledListItem
                              key={index}
                              className={selectedValue === `${value}`}
                              id={value}
                              onClick={(e) => {
                                handleCityChange(e, false);
                              }}
                            >
                              {value}
                            </StyledListItem>
                          );
                        })}
                        <StyledListItem
                          onClick={() => {
                            toggleOtherRegion(true);
                          }}
                        >
                          Other
                        </StyledListItem>
                      </StyledList>
                    </div>
                    {!isOpen ? (
                      <div class="pointer-events-none absolute inset-y-0 right-0 bottom-[0px] flex items-center px-2">
                        <IconDown />
                      </div>
                    ) : null}
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    outline="green"
                    className="w-[100%] h-[50px] my-[20px]"
                  >
                    Shop Now
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

DeliveryAvailabilty.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DeliveryAvailabilty;
