import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  activeModal,
  postRequest,
  putRequest,
  userLogin,
} from "../../../redux/action";
import { AfroshopLogo, EmailIcon } from "../../../images";
import { Button } from "../../shared";
import { useForm } from "react-hook-form";
import { getTokenFromCookie } from "../../../utils/constants";

function EmailVerification() {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm({ mode: "all" });

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const handleOtp = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([
      ...otp.map((value, idx) => (index === idx ? element.value : value)),
    ]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    otp.join("");
  };

  const allOtpsNotEmpty = otp.every((str) => str.length > 0);

  const token = getTokenFromCookie();

  // change confirmation to false
  const updateUserConfirmation = async () => {
    try {
      const [success, responseData] = await putRequest(
        `/api/users/${user.id}`,
        {
          confirmed: false,
        },
        token
      );
      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        toast.error(
          `${
            responseData?.error?.message || "An error occured please try again"
          }`,
          { autoClose: 2000 }
        );
      } else {
        dispatch(userLogin(responseData));
      }
    } catch (error) {
      toast.error(`An error occured please try again`, { autoClose: 2000 });
    }
  };

  // resend user otp
  const sendUserOtp = async (user) => {
    setLoading(true);
    try {
      const [success, response] = await postRequest("/api/generate-otps", {
        email: user.email,
        user,
      });

      if (!success || response?.error) {
        console.error(response.error.message);
        toast.error(
          `${
            response?.error?.message ||
            "An error occured while sending your otp"
          }`,
          { autoClose: 2000 }
        );
      } else {
        toast.success("otp sent to your email adress", { autoClose: 2000 });
        dispatch(activeModal("emailVerify"));
      }
    } catch (error) {
      toast.error(`An error occured while sending your otp`, {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const [success, responseData] = await postRequest("/api/verify-otps", {
        otp: Number(otp.join("")),
        user,
      });

      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        toast.error(
          `${
            responseData?.error?.message ||
            "An error occured while verifying your otp"
          }`,
          { autoClose: 2000 }
        );
        dispatch(activeModal("emailVerify"));
      } else {
        await updateUserConfirmation();
        dispatch(activeModal("nameVerify"));
      }
    } catch (error) {
      toast.error(`An error occured while verifying your otp`, {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center">
      <div className="md:w-[500px] h-[840px] w-full lg:h-[700px] md:py-[30px] overflow-y-auto md:mt-6 py-7  bg-[#FFFFFF] rounded-lg flex flex-col justify-center">
        <div className=" flex flex-col justify-center items-center gap-6">
          <AfroshopLogo />
          <EmailIcon />
          <div className="flex flex-col justify-center items-center gap-3">
            <h5 className="text-base font-bold md:text-xl">
              Verify your email
            </h5>
            <div className="flex flex-col gap-2 text-[#CCCCCC] text-xs md:text-base justify-center items-center">
              <span>Please enter the 4 digit code sent to </span>
              <span>{user?.email}</span>
            </div>
          </div>
          <form
            className="flex flex-col items-center justify-center bg-[#FFFFFF1A]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-row gap-4 sm:gap-9">
              {otp.map((data, index) => {
                return (
                  <input
                    type="text"
                    maxLength="1"
                    name="otp"
                    key={index}
                    value={data}
                    onChange={(event) => handleOtp(event.target, index)}
                    onFocus={(event) => event.target.select()}
                    className="w-[64px] h-[64px] text-2xl font-bold border border-[#CCCCCC] focus:outline-none focus:ring-0 focus:border-[#186F3D] px-5 py-3"
                  />
                );
              })}
            </div>

            <div className="flex flex-col items-center gap-6 w-[88%] mt-4">
              <Button
                size
                variant="transparent"
                outline="white"
                className="text-[#186F3D] text-sm md:text-base"
                onClick={async () => await sendUserOtp(user)}
              >
                Resend Code
              </Button>

              <Button
                className=" sm:w-[370px] w-[300px]"
                icon="white"
                type="submit"
                loading={loading}
                disabled={!allOtpsNotEmpty}
              >
                Verify
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
