import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeModal, undoUserInfo, hideForm, hideEditAddressForm } from "../../../redux/action";
import { Button } from "../../shared";

const CancelModal = () => {

  const showUserForm = useSelector((state) => state.showUserForm);
  const dispatch = useDispatch()

const handleBackdropClick = (event) => {
  if (event.target === event.currentTarget) {
    dispatch(activeModal(''));
  }
}; 

const handleDiscard = () => {
   dispatch(undoUserInfo());
   dispatch(activeModal(''));
   if (showUserForm) {
    dispatch(hideForm());
   } else {
    dispatch(hideEditAddressForm())
   }
}

  return (
    <div className=" flex items-center justify-center h-screen fixed inset-0 bg-blackTransparent bg-opacity-10 z-50" onClick={handleBackdropClick}>
      <div className="flex flex-col items-center px-[24px] sm:px-[32px] py-[32px] w-[342px] h-[199px] sm:h-[208px] md:w-[585px] bg-white rounded-[8px]" >
        <div className="w-full h-[135px] sm:h-[144px]">
          <div className="">
            <div className="text-[16px] sm:text-[20px] font-bold text-gray-400">Discard Changes?</div>
            <p className="text-[13px] sm:text-[16px] text-[#cccccc] py-[8px]">Are you sure you want to discard edit changes you just made?</p>
          </div>
          <div className="flex items-end justify-between w-[100%] mt-[12px] sm:mt-[30px]">
            <div className="w-[48%] text-green">
              <Button
                type="button"
                variant="transparent"
                outline="green"
                className="w-[100%] h-[40px]"
                onClick={() => dispatch(activeModal(''))}
              >
                No, thanks
              </Button>
            </div>
            <div className="w-[48%]">
              <Button
                type="button"
                variant="primary"
                outline="green"
                className="w-[100%] h-[40px]"
                onClick={handleDiscard}
              >
                Yes, discard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelModal;
