import React from "react";
import { PageLayout, NotFoundHeader, SEO } from "../components";

const PageNotFound = () => {
  return (
    <>
      <SEO
        url="/*"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
        description="404 Page"
      />
      {/* <PageLayout>
        <NotFoundHeader />
      </PageLayout> */}
    <NotFoundHeader />
    </>
  );
};

export default PageNotFound;
