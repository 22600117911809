import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Button, Checkbox } from "../shared";
import InputComponent from "../shared/inputComponent";
import useInput from "../../hooks/useInput";

import {
  AfroshopLogo,
  RoundedInstagramIcon,
  RoundedWhatsappIcon,
  XIcon,
  BlackUserIcon,
  BlackMessageIcon,
} from "../../images";
import {
  AccessBag,
  AccessSmallBag,
  AccessSmallBasket,
  AccessSmallVeggies,
  AccessVeggies,
  AcessBasket,
  MobileBag,
  MobileBasket,
  MobileShopping,
  MobileSmallBag,
  MobileSmallBasket,
  MobileSmallShopping,
  MarkIcon,
} from "../../images/access";
import styles from "./early-access.module.css";
import { postRequest, activeModal } from "../../redux/action";

const EarlyAccess = () => {
  const [activeDiv, setActiveDiv] = useState(0);
  const [addCheckbox, setAddCheckbox] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailInput = useInput("", "emal");
  const firstNameInput = useInput("", "text-special");
  const lastNameInput = useInput("", "text-special");

  const dispatch = useDispatch();

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const bigImagesSet = isDesktop
    ? [AccessBag, AccessVeggies, AcessBasket]
    : [MobileBag, MobileShopping, MobileBasket];
  const smallImagesSet = isDesktop
    ? [AccessSmallBag, AccessSmallVeggies, AccessSmallBasket]
    : [MobileSmallBag, MobileSmallShopping, MobileSmallBasket];

  const socialIcons = [
    {
      icon: RoundedWhatsappIcon,
      link: "https://whatsapp.com/channel/0029VaL5EOEJP21B6qVu430w",
      alt: "whatsapp-icon",
    },
    {
      icon: RoundedInstagramIcon,
      link: "https://www.instagram.com/afroshop_ca",
      alt: "instagram-icon",
    },
    {
      icon: XIcon,
      link: "https://twitter.com/afroshop_ca",
      alt: "x-icon",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDiv((prevActiveDiv) => {
        if (prevActiveDiv === 2) {
          return 0;
        } else {
          return prevActiveDiv + 1;
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      isSubscribed: addCheckbox,
    };
    try {
      const apiUrl = `/api/early-accesses`;
      const [success, response] = await postRequest(apiUrl, formData);
      if (
        success &&
        response.message ===
          "The link to join Afroshop Early access has been sent to your email"
      ) {
        reset();
        setAddCheckbox(false);
        dispatch(activeModal("earlyAccessSurvey"));
        setIsSuccess(true);
        setLoading(false);
      } else {
        toast.error(
          `${
            response?.error?.message ||
            "An error occured while submiting your info"
          }`,
          { autoClose: 2000 }
        );
      }
    } catch (error) {
      toast.error("An error occured while submiting your info", {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const activeImage = (index) => {
    switch (index) {
      case 0:
        return index === activeDiv ? bigImagesSet[0] : smallImagesSet[0];
      case 1:
        return index === activeDiv ? bigImagesSet[1] : smallImagesSet[1];
      case 2:
        return index === activeDiv ? bigImagesSet[2] : smallImagesSet[2];
      default:
        return "";
    }
  };

  return (
    <section className={styles["hero-background"]}>
      <div className="px-4 xl:pr-0 xl:pl-20 mt-4 lg:mt-12 flex-1 mb-10 xl:mb-0">
        <AfroshopLogo className=" mb-6 xl:mb-20" />
        <h2 className="w-[342px] text-center xl:text-left xl:w-[568px] font-bold text-xl xl:text-5xl leading-8 xl:leading-[56px] text-green m-auto xl:m-0 my-4 xl:my-10">
          Authentic African Groceries, Delivered to You!
        </h2>
        <p className="text-gray-400 text-center xl:text-left w-[342px] xl:w-[525px] text-[13px] xl:text-base !leading-[23px] lg:!leading-8 m-auto xl:m-0">
          Get exclusive early access to our online African grocery store! Join
          our pre-launch list now to explore a curated selection of authentic
          ingredients, spices, and culinary delights when we launch. We will be
          in touch when we go live.
        </p>
        {isSuccess ? (
          <div className="flex items-center my-8 xl:my-16">
            <MarkIcon />
            <p className="ml-4 font-semibold text-green leading-6 w-[301px] md:w-[600px]">
              Congratulations! 🎉 <br /> The link to join Afroshop Early access
              has been sent to your email <br />
              You're on your way to hassle-free grocery shopping.
            </p>
          </div>
        ) : loading ? (
          <div className="flex flex-col items-center justify-center mt-8">
            <Oval color="#186F3D" height={40} width={40} />
          </div>
        ) : (
          <>
            <form
              className="mt-6 xl:mt-12 mb-6 flex flex-col xl:w-[525px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col xl:flex-row justify-between w-full mb-2">
                <div className="w-full xl:w-1/2">
                  <label className="text-[#B3B3B3] text-xs font-normal">
                    First Name
                  </label>
                  <InputComponent
                    type="text"
                    placeholder="Enter First Name"
                    fieldName="firstName"
                    requiredMessage="Enter First Name"
                    patternValue={/^[A-Za-z\s'-]+$/}
                    patternMessage="Only alphabetic characters allowed"
                    leftIcon={BlackUserIcon}
                    compoundValidation={true}
                    register={register}
                    errors={errors}
                    className={
                      "bg-[#F2F2F2] w-full !h-[40px] border border-solid border-[#186F3D] focus:outline-none text-base rounded pl-2 mt-2"
                    }
                    value={firstNameInput.value}
                    handleChange={(e) => {
                      firstNameInput.handleInputChange(e);
                    }}
                  />
                </div>

                <div className="w-full xl:w-1/2 xl:ml-8">
                  <label className="text-[#B3B3B3] text-xs font-normal">
                    Last Name
                  </label>
                  <InputComponent
                    type="text"
                    placeholder="Enter Last Name"
                    fieldName="lastName"
                    requiredMessage="Enter Last Name"
                    patternValue={/^[A-Za-z\s'-]+$/}
                    patternMessage="Only alphabetic characters allowed"
                    leftIcon={BlackUserIcon}
                    compoundValidation={true}
                    register={register}
                    errors={errors}
                    className={
                      "bg-[#F2F2F2] w-full !h-[40px] border border-solid border-[#186F3D] focus:outline-none text-base rounded pl-2 mt-2"
                    }
                    value={lastNameInput.value}
                    handleChange={(e) => {
                      lastNameInput.handleInputChange(e);
                    }}
                  />
                </div>
              </div>
              <label className="text-[#B3B3B3] text-xs font-normal">
                Email
              </label>
              <InputComponent
                type="email"
                placeholder="Enter Email"
                fieldName="email"
                requiredMessage="Enter Valid Email"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                leftIcon={BlackMessageIcon}
                compoundValidation={true}
                register={register}
                errors={errors}
                className={
                  "bg-[#F2F2F2] w-full !h-[40px] border border-solid border-[#186F3D] focus:outline-none text-base rounded pl-2 mt-2"
                }
                value={emailInput.value}
                handleChange={(e) => {
                  emailInput.handleInputChange(e);
                }}
              />

              <Button className="w-full mt-6" type="submit" disabled={!isValid}>
                Get Early Access
              </Button>
            </form>
            <Checkbox
              handleChange={(event) => setAddCheckbox(event.target.checked)}
              isChecked={addCheckbox}
            >
              <p className="xl:text-left text-gray-400 text-[10px] xl:text-base w-full xl:w-[457px] ml-2">
                Yes, I would like to receive occasional emails with updates,
                news, and promotions.
              </p>
            </Checkbox>
          </>
        )}
        <div className="absolute bottom-4 xl:static xl:mt-32 flex items-center xl:items-start xl:flex-col">
          <p className="text-[13px] xl:text-xl xl:font-bold text-green leading-[23px] xl:leading-8">
            Join/Follow our socials
          </p>
          <div className="flex justify-between w-[180px] xl:w-[192px] xl:mt-4 xl:mb-8 ml-4 xl:ml-0">
            {socialIcons.map((social) => (
              <Link to={social.link} target="_blank">
                <img
                  src={social.icon}
                  alt={social.alt}
                  className="cursor-pointer"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end xl:justify-normal">
        {[1, 2, 3].map((_, index) => (
          <img
            key={index}
            src={activeImage(index)}
            alt={`shopping-${index}`}
            className="transition-opacity duration-1000 ease-in-out"
          />
        ))}
      </div>
    </section>
  );
};

export default EarlyAccess;
