import React, { useEffect, useState } from "react";
import { DriversApplicationLogo, GreenUpload, UserAvatar } from "../../images";
import styled from "styled-components";
import { Button } from "../shared";
import UploadDocument from "./doucument-upload";
import DriversFormInputs from "./form-inputs";
import { useForm } from "react-hook-form";



const OrderedList = styled.ol`
      list-style: decimal outside !important;
      `;


const DriversApplication = () => {

    const credentials = ["Driver's Liscense", "Vehicle Registration", "Vehicle Insurance", "Vehicle Inspection Certificate", "Proof of Residency"]
    const [documentUpload, setDocumentUpload] = useState({})
    const [image, setImage] = useState({ preview: "", raw: "" });

   
   //handling profile pics upload
    const handleProfilePicChange = async (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };


// handling document uploads
    useEffect(() => {
        const docs = {}
        for (const doc of credentials) {
            docs[doc] = '';
        }

        setDocumentUpload(docs);
    }, [])

    const handleUploads = (filename, type) => {
        const doc = documentUpload;
        if (type.endsWith("/jpeg") || type.endsWith("/jpg") || type.endsWith("/pdf") || type.endsWith("/png")) {
            doc[filename] = "Progress";

            setTimeout(() => {
                doc[filename] = "Completed";
                setDocumentUpload({ ...doc });
            }, 2000)
        } else {
            doc[filename] = "Error";
            setDocumentUpload({ ...doc });
        }
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "all" });


    const onSubmit = (data) => {
        console.log(data);
      };


    return (
        <div className="sm:mb-20">
            <div className="w-full sm:h-[200px] h-[100px] bg-[#F2F2F2] flex justify-center items-center">
                <img src={DriversApplicationLogo} className="sm:w-[409px] sm:h-[131.33px] w-[184px] h-[59.08px]" />
            </div>

            <div className="sm:px-20 px-6">

                <div className="sm:mt-8 mt-4 w-full sm:w-[667px] ">
                    <p className="sm:text-[39px] text-[20px] font-bold text-[#186F3D] sm:leading-[56px] leading-[32px]">Driver's Application Form</p>
                    <p className="sm:text-[20px] sm:leading-[32px] text-[13px] leading-[23px] text-[#333333] font-normal">Unlock Delivery Opportunities with AfroShop! <br />
                        Submit your details to be eligible to deliver african groceries with us.</p>
                </div>

                <div className="sm:mt-8 mt-4 w-full">
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <div>

                            <div className="flex sm:gap-6 gap-4 items-center">
                                <label htmlFor="profile-pic-upload" className="cursor-pointer">
                                    <img src={image && image.preview ? image.preview : UserAvatar} alt="profile-pic" className="w-[100px] h-[100px] rounded-full" />
                                </label>

                                <label htmlFor="profile-pic-upload" className="cursor-pointer">
                                    <div className="flex justify-center items-center gap-2 border border-[#186F3D] rounded w-[140px] h-[40px] text-[#186F3D]">
                                        <GreenUpload className="" />
                                        <span>Upload</span>
                                    </div>
                                </label>
                            </div>

                            <input type="file" id="profile-pic-upload" name="profile-pic" className="hidden" accept="image/*" onChange={handleProfilePicChange} />
                        </div>

                        <div className="sm:mt-8 mt-4">

                            <DriversFormInputs register={register} errors={errors}/>

                        </div>

                        <div className="sm:mt-8 mt-4">
                            <p className="font-bold text-[20px] leading-[32px] text-[#186F3D]">Upload Documents</p>

                            <OrderedList className="sm:mt-8 mt-4 mb-12 sm:mb-20 ml-4 " >
                                {
                                    credentials.map((text, index) => {
                                        return (
                                            <UploadDocument name={text} key={index} list={index} handleUploads={handleUploads} documentUpload={documentUpload} />
                                        )
                                    })
                                }

                            </OrderedList>
                        </div>

                        <Button type="submit" size="full" variant="primary" outline="green" className="mb-12 py-1">Submit</Button>

                    </form>
                </div>
            </div >
        </div >
    )
}


export default DriversApplication;