import React from "react";
import { info, locate, } from "../../../images";
import { PropTypes } from "prop-types";
import { Button } from "../../shared";
import InputComponent from "../../shared/inputComponent";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { activeModal } from "../../../redux/action";

function NoDelivery() {

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    dispatch(activeModal(''));
  };

  return (
        <div className="flex items-center justify-center h-screen fixed inset-0 bg-blackTransparent bg-opacity-10 rounded-[4px]">
          <div
            className="flex items-center justify-center py-[150px] w-[400px] gap-4 h-[553px] md:w-[800px] bg-white px-4 mx-4 rounded-4px rounded-[4px]"
          >
            <div>
              <div className="mx-auto mb-[30px]">
                <img src={locate} className="mx-auto" alt="location pin" />
              </div>
              <div className="text-center w-full md:w-[398px] gap-2 bg-white">
                <h1 className="my-4 text-green text-[20px] font-[700]">
                  Hi there!
                </h1>
                <p className="mb-2 leading-6 text-[#333333] text-[16px]">
                  We would like to know where you’re shopping from.
                </p>
                <div>
                  <div className="w-[327px] border border-solid border-[#FFD60A] rounded-[4px] mx-auto mb-2 p-3 flex items-center bg-[rgba(255,225,10,0.05)]">
                    <img src={info} alt="warning" />
                    <p className="text-left text-[10px] text-gray-400 leading-[15px] ml-2">
                      Apologies, we don't deliver to your location currently.
                      Explore our website and stay updated by submitting your
                      email for future delivery notifications.
                    </p>
                  </div>
                  <p className="text-[#B3B3B3] text-[13px] mx-auto w-[327px] gap-2 text-left py-2">
                    Email Address
                  </p>
                </div>
                <form
                  className="w-[327px] mx-auto"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <InputComponent
                    fieldName="Email"
                    compoundValidation={true}
                    required
                    placeholder="Enter Email"
                    title={"email"}
                    register={register}
                    errors={errors}
                    requiredMessage={"Enter Valid Email"}
                    name={"Email"}
                    patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                    patternMessage="Invalid email address"
                    type={"email"}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    outline="green"
                    className="w-[100%] h-[50px] mt-[20px] mb-[20px]"
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
  );
}

NoDelivery.defaultProps = {
  value: true,
};

NoDelivery.propTypes = {
  value: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoDelivery;
