import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import axios from 'axios';
import "./index.css";
import MyRoutes from "./Routes";
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer />
      <MyRoutes />
    </React.StrictMode>
  </Provider>
);
