import React from "react";
import { YellowBanner } from "../banner";
import ModalFactory from "../modal/modalFactory";

const AcessPageLayout = ({ children }) => {

  return (
    <section>
      <YellowBanner/>
      <div className="bg-white w-full h-full flex flex-col gap-[60px] md:gap-[80px] large-screen relative overflow-hidden">{children}</div>
      <ModalFactory/>
    </section>
  );
};

export default AcessPageLayout;
