import React from "react";
import styled from "styled-components";
import { ButtonChecks } from "../../images";


const CheckedWrapper = styled.div`
    display: none;
`;

const CardButtons = styled.label`
    cursor: pointer;
`;

const CheckedCheckbox = styled.input`
    display: none;
    
    &:checked + ${CardButtons} ${CheckedWrapper} {
        display: block;
      }

      &:checked + ${CardButtons}{
        border: solid 1px #186F3D;
      }
`;

const CheckedButtons = ({ deliveryDates, selectedButton, handleData }) => {

    return (
        <div>
            <div className="flex mt-4">
                {
                    deliveryDates.map(({id, day, date}, index) => {
                        return (
                            <div key={id || index} className="flex w-1/3">
                                <CheckedCheckbox type="checkbox" id={id} checked={selectedButton === index} onChange={() => handleData("dayIndex", index)} />
                                <CardButtons htmlFor={id} className="w-full text-center text-[10px] leading-[15px] text-[#333333] border border-[#E6E6E6] py-1 mr-6 rounded relative">
                                    <CheckedWrapper className="absolute top-[-3px] right-[-2px]">
                                        <ButtonChecks className="" />
                                    </CheckedWrapper>
                                    <p>{day}</p>
                                    <p>{date}</p>
                                </CardButtons>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default CheckedButtons;