export const MENU_ITEMS = [
  {
    title: "Category",
    type: "Radio",
    submenu: [
      "Diary",
      "Produce",
      "Protein",
      "Pantry",
      "Pastries",
      "Beverages",
      "Snacks",
    ],
  },
  {
    title: "Price",
    type: "Checkbox",
    submenu: [
      [200, Infinity],
      [100, 200],
      [50, 100],
      [25, 50],
      [0, 25],
    ],
    // submenu: ['$200 & Above', '$100 to $200', '$50 to $100', '$25 to $50', 'Under $25']
  },
  {
    title: "Rating",
    type: "Rating",
    submenu: [5, 4, 3, 2, 1],
  },
];
export const LOCATION = [
  {
    id: "toronto",
    value: "Toronto",
  },
  {
    id: "mississauga",
    value: "Mississauga",
  },
  {
    id: "brampton",
    value: "Brampton",
  },
  {
    id: "markham",
    value: "Markham",
  },
  {
    id: "vaughan",
    value: "Vaughan",
  },
  {
    id: "richmond-hill",
    value: "Richmond Hill",
  },
  {
    id: "pickering",
    value: "Pickering",
  },
  {
    id: "ajax",
    value: "Ajax",
  },
  {
    id: "whitby",
    value: "Whitby",
  },
  {
    id: "oshawa",
    value: "Oshawa",
  },
];
