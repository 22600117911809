import { HeartIcon, Shopping, DeliveryIcon } from '../images'
export const WELCOME_DATA =[
    {
        image: HeartIcon,
        title: "Select favorite store(s)",
        description: "We have a variety of stores to near you."
    },
    {
        image: Shopping,
        title:"Shop groceries",
        description: "Shop products such as grains, spices, beverages, fresh produce, etc."
    },
    {
        image: DeliveryIcon,
        title: "Delivery service",
        description: "Have your grocery delivered to your home, office or wherever you are."
    }
]