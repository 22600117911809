import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, activeModal, guestLogOut } from "../../../redux/action";
import { Link } from "react-router-dom";
import {
  ChevronRight,
  AvatarGrey,
  LogoutIcon,
  UserAvatar,
} from "../../../images";
import { handleBackdropClick } from "../../../helpers/backdropClick";
import { removeTokenFromCookie } from "../../../utils/constants";

function LoggedInProfile() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(activeModal(""));
  };

  const handleUserLogOut = (e) => {
    e.stopPropagation();
    console.log('user clicked logout on desktop')
    closeModal();
    removeTokenFromCookie();
    dispatch(guestLogOut());
    dispatch(logOutUser());
  };

  const [showModal, setShowModal] = useState(true)
  useEffect(() => {
    const handleResize = () => {
      setShowModal(window.innerWidth >= 766);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  if (!showModal) return null

  return (
    <div
      className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-10"
      onClick={(e) => handleBackdropClick(e, closeModal)}
    >
      <div className="fixed top-[10rem] right-[10rem] bg-opacity-25 z-[800]">
        <div className="md:w-[320px] md:h-[320px] bg-[#FFFFFF] rounded-lg flex flex-col justify-center items-center gap-6 px-6 py-6">
          <Link to={"/user-info"} onClick={closeModal}>
            <div className="flex flex-col items-center gap-4 mb-3">
              <img
                className="w-[50px] md:w-[100px] md:h-[100px] h-[50px] cursor-pointer object-contain rounded-[50%]"
                src={user?.avatarUrl || UserAvatar}
                alt="userImage"
              />
              <h6 className="font-bold text-base">
                {user?.firstName
                  ? `${user?.firstName} ${user?.lastName}`
                  : "Guest User"}
              </h6>
            </div>
          </Link>
          <hr className=" bg-[#E6E6E6] h-[1px] w-full" />
          <Link to={"/user-info"} className="w-full" onClick={closeModal}>
            <div
              className="flex justify-between gap-7 w-full"
            >
              <div className="flex gap-6">
                <img src={AvatarGrey} alt="avatar" />
                <p className="text-[#333333] text-base">Manage Account</p>
              </div>
              <img src={ChevronRight} alt="RightIcon" />
            </div>
          </Link>
          <div className="flex justify-between w-full h-6 cursor-pointer" onClick={handleUserLogOut}>
            <label
              className="text-[#FF3B30] font-normal text-base"
            >
              Logout
            </label>
            <img src={LogoutIcon} alt="LogoutIcon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoggedInProfile;
