import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  AfroshopLogo,
  Sms,
  eyeSlash,
  googleLogo,
  lock,
  guest,
} from "../../../images";

import { Button } from "../../shared";
import InputComponent from "../../shared/inputComponent";
import ConnectButton from "../../../googleLoginButton";
import { useForm } from "react-hook-form";
import {
  activeModal,
  guestLogOut,
  userLogin,
  postRequest,
} from "../../../redux/action";
import { handleBackdropClick } from "../../../helpers/backdropClick";
import { AFROPOT_TOKEN, expirationDate } from "../../../utils/constants";

function AuthScreen({ auth }) {
  const [showPassword, setShowPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [refUserCode, setUserRefCode] = useState(null)
  const loginGuest = useSelector((state) => state.loginGuest);
  const  getActiveModalName  = useSelector((state) => state.getActiveModalName);

  const location = useLocation();
  
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const hasRefSegment = location.pathname.includes('/ref');
  const ref = refUserCode || Cookies.get('refCode');

  function handleShowPassword() {
    if (!showPassword) setShowPassword(true);
    else setShowPassword(false);
  }

  const closeModal = () => {
    dispatch(activeModal(""));
  };

  useEffect(() => {
   if (hasRefSegment) {
    const urlPath = location.pathname.split('/');
    const lastUrlString = urlPath[urlPath.length - 1];
  
    const refCode = lastUrlString.slice(-6)
    setUserRefCode(refCode)
   }  
  }, [hasRefSegment])


  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleUserLogin = async (value) => {
    if (loginGuest) {
      navigate("/confirm-order");
    }
    setLoading(true);
    try {
      const [success, responseData] = await postRequest("/api/auth/local", {
        identifier: value.email,
        password: value.password,
      });
      if (!success || responseData?.error) {
        console.error(responseData?.error?.message);
        toast.error(
          `${responseData?.error?.message || "An Error occured while logging in"
          }`,
          { autoClose: 2000 }
        );
      } else {
        reset();
        dispatch(userLogin(responseData?.user));
        Cookies.set(AFROPOT_TOKEN, responseData?.jwt, { expires: expirationDate });
        dispatch(activeModal(""));
        if (!loginGuest) {
          navigate("/");
        }
      }
    } catch (error) {
      toast.error(`An error occured while logging in`, { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const handleGuestNavigation = () => {
    dispatch(guestLogOut());
    dispatch(activeModal(""));
    navigate("/confirm-order");
  };

  // send user otp
  const sendUserOtp = async (user) => {
    try {
      const [success, response] = await postRequest("/api/generate-otps", {
        email: user.email,
        user,
      });

      if (!success || response?.error) {
        console.error(response?.error?.message);
        toast.error(
          `${response?.error?.message || "An error occured while sending an Otp"
          }`,
          { autoClose: 2000 }
        );
      } else {
        dispatch(activeModal("emailVerify"));
      }
    } catch (error) {
      toast.error(`An error occured while sending an Otp`, { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    const value = getValues();
    if (auth) {
      setLoading(true);
      try {
        const [success, responseData] = await postRequest(
          "/api/auth/local/register",
          {
            username: value.email,
            email: value.email,
            password: value.password,
            ...(ref && {referralCode: ref })
          }
        );

        if (!success || responseData?.error) {
          console.error(responseData.error.message);
          toast.error(
            `${responseData?.error?.message ||
            "An error occured while signing up"
            }`,
            { autoClose: 2000 }
          );
        } else {
          dispatch(userLogin(responseData?.user));
          Cookies.set(AFROPOT_TOKEN, responseData?.jwt, { expires: expirationDate });
          await sendUserOtp(responseData?.user);
          reset();
        }
      } catch (error) {
        toast.error(`An error occured while signing up`, { autoClose: 2000 });
      } finally {
        setLoading(false);
      }
    } else {
      handleUserLogin(value);
    }
  };

  return (
    <div>
      <div
        className={`fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 ${getActiveModalName?.length > 0 ? "flex" : "hidden"
          } justify-center items-center z-[200]`}
        onClick={(e) => handleBackdropClick(e, closeModal)}
      >
        <div className="md:w-[585px] w-[98%] md:py-[32px] overflow-y-auto md:mt-6 py-7 px-[24px] bg-[#FFFFFF] rounded-lg  z-[2000]">
          <div className=" flex flex-col justify-center items-center gap-6">
            <AfroshopLogo />
            <div className="w-[342px] flex flex-col justify-center items-center">
              <h5 className="text-[#333333] text-[16px] md:text-[20px] font-bold mt-5 mb-1">
                {auth ? "Sign Up" : "Log In"}
              </h5>
              <span className="text-[#CCCCCC] text-[13px] md:text-[16px]">
                {auth
                  ? "Register to complete your order."
                  : "Log In to complete your order."}
              </span>
            </div>
            
              <ConnectButton provider="google" />
              
            {loginGuest && (
              <button
                onClick={handleGuestNavigation}
                className="md:w-[400px] w-[98%] flex flex-row justify-center items-center gap-2 pl-2 py-2 border border-[#CCCCCC] border-solid text-[#333333] text-base rounded lg:flex md:flex"
              >
                <img src={guest} alt="guest" />
                <span className="text-[16px] text-[#333333] font-normal">
                  Checkout as a Guest
                </span>
              </button>
            )}

            <span className="text-[#CCCCCC] text-[13px] md:text-[16px]">
              or
            </span>

            <form
              className="flex flex-col gap-2 w-full justify-center items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="md:w-[400px] w-full mt-2 flex flex-col gap-2">
                <label className="text-[#B3B3B3] text-xs font-normal">
                  Email
                </label>

                <InputComponent
                  type="email"
                  placeholder="Enter email"
                  fieldName="email"
                  requiredMessage="Enter valid email"
                  patternValue={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
                  patternMessage="Invalid email address"
                  compoundValidation={true}
                  register={register}
                  errors={errors}
                  leftIcon={Sms}
                />
              </div>

              <div className="md:w-[400px] w-full mt-2 flex flex-col gap-2">
                <label className="text-[#B3B3B3] text-xs font-normal">
                  Password
                </label>

                <InputComponent
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  fieldName="password"
                  requiredMessage="Enter valid password"
                  // patternValue={/^[A-Za-z]\w{7,14}$/}
                  compoundValidation={true}
                  register={register}
                  errors={errors}
                  leftIcon={lock}
                  rightIcon={eyeSlash}
                  onIconClick={handleShowPassword}
                  iconClassName="cursor-pointer"
                />

                {!auth && (
                  <p
                    className="text-right text-[10px] font-normal cursor-pointer w-[fit-content] self-end"
                    onClick={() => dispatch(activeModal("reset"))}
                  >
                    Forgot Password?
                  </p>
                )}
              </div>

              <Button
                className="mt-3 md:w-[400px] w-[86vw]"
                icon="white"
                type="submit"
                loading={loading}
              >
                {auth ? "Sign Up" : "Log In"}
              </Button>
            </form>

            <div>
              <span className="text-[#CCCCCC] text-[13px] md:text-[16px] font-normal">
                {auth ? "Already have an account?" : "Don’t have an account?"}{" "}
                <button
                  className="text-[#186F3D] underline"
                  onClick={() => {
                    auth
                      ? dispatch(activeModal("login"))
                      : dispatch(activeModal("signUp"));
                  }}
                >
                  {auth ? "Log In" : "Sign Up"}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthScreen;
