import React, { useState } from "react";
import { toast } from "react-toastify";
import { AfroshopLogo, Sms } from "../../../../images";
import { useDispatch } from "react-redux";
import { activeModal } from "../../../../redux/action";
import { Button } from "../../../shared";
import InputComponent from "../../../shared/inputComponent";
import { useForm } from "react-hook-form";
import { postRequest } from "../../../../redux/action";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      dispatch(activeModal(""));
    }
  };
  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = async () => {
    const value = getValues();
    setLoading(true);
    try {
      const [success, responseData] = await postRequest(
        "/api/auth/forgot-password",
        {
          email: value.email,
        }
      );

      if (!success || responseData?.error) {
        toast.error(
          responseData.error.message || "An error occured resetting password"
        );
      } else {
        reset();
        toast.success("Reset password link sent to your email.");
        dispatch(activeModal(""));
      }
    } catch (error) {
      toast.error(`An error occured resetting password`, { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="md:w-[585px] md:h-[370px] py-[32px] px-[24px] w-full h-full gap-4 bg-[#FFFFFF] flex flex-col justify-center items-center rounded-lg">
        <AfroshopLogo />
        <div className="w-[342px] flex flex-col justify-center items-center">
          <h5 className="text-[#333333] text-[16px] md:text-[20px] font-bold mt-5 mb-1">
            Reset Password
          </h5>
          <span className="text-[#CCCCCC] text-[13px] md:text-[16px]">
            We’ll email you a password reset link
          </span>
        </div>
        <form
          className="flex flex-col gap-2 w-full justify-center items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="md:w-[400px] w-[98%] mt-2 gap-2">
            <label className="text-[#B3B3B3] text-xs font-normal">Email</label>
            <InputComponent
              type="email"
              placeholder="Enter email"
              fieldName="email"
              requiredMessage="Enter valid email"
              patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
              patternMessage="Invalid email address"
              compoundValidation={true}
              register={register}
              errors={errors}
              leftIcon={Sms}
            />
          </div>
          <Button
            className="mt-3 md:w-[400px] w-[86vw]"
            icon="white"
            type="submit"
            loading={loading}
          >
            Reset
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
