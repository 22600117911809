import Cookies from "js-cookie";

export const AFROPOT_TOKEN = 'afropot-auth-token'
export const SELECTED_LOCATION = 'afropot-selected-location'

export const BACKEND_URL = process.env.REACT_APP_API_URL.replace('/api', '')

export const renderValidUrl = (url) => {
  if (url?.startsWith('http://') || url?.startsWith('https://')) {
    // Absolute URL, return as is
    return url;
  } else {
    // Relative URL, append backendUrl
    return BACKEND_URL + url;
  }
};

  // Set a cookie that expires in 3 hours
const expirationTimeInMinutes = 3 * 60;
export const expirationDate = new Date(new Date().getTime() + (expirationTimeInMinutes * 60 * 1000));

export const setCookieWithExpiry = (value) => Cookies.set(AFROPOT_TOKEN, value, { expires: expirationDate, path: "/", secure: process.env.NODE_ENV === "production" });

export const getTokenFromCookie = () =>  Cookies.get(AFROPOT_TOKEN);

export const removeTokenFromCookie = () => Cookies.remove(AFROPOT_TOKEN , { path: '/' });

