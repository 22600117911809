import React from "react";

const SelectField = ({
  requiredMessage,
  options,
  disabled,
  onChange,
  placeholder,
  register,
  errors,
  fieldName,
}) => {
  return (
    <select
      {...register(fieldName, {
        required: requiredMessage,
      })}
      className="bg-inherit w-full border-none focus:outline-none text-[16px] font-normal placeholder-[#999999]"
      placeholder={placeholder}
      disabled={disabled}
    >
      {[{label: placeholder, value: placeholder}, ...options]?.map(({ value, label }, index) => (
        <option
          value={value === placeholder ? "" : value}
          selected={value === placeholder}
          key={value}
        >
          {value}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
