import { SEO, AcessPageLayout} from "../components";
import EarlyAccess from "../components/early-access";

function EarlyAccessPage() {
  return (
    <>
      <SEO
        url="/early-access"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
        description=""
      />
      <AcessPageLayout>
        <EarlyAccess/>
      </AcessPageLayout>
    </>
  );
}

export default EarlyAccessPage;