import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CardButtons = styled.label`
    cursor: pointer;
`;

const CheckedCheckbox = styled.input`
    display: none;
      &:checked + ${CardButtons}{
        background-color: #186F3D;
        color: #FFFFFF;
      }
`;

const InputNumber = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StoreTip = ({ setTip, tip }) => {
    const [selectedButton, setSelectedButton] = useState(null);
    const [otherTip, setOtherTip] = useState(false);
    const [tipAmount, setTipAmount] = useState(0)

    const handleClickedTip = (e, tip) => {
        e.stopPropagation()
        if (selectedButton === tip) {
            setSelectedButton(null)
            setTipAmount(0)
        }
        else {
            setSelectedButton(tip)
            setTipAmount(tip)
        }
    }
    const handleClickedOther = (e) => {
        e.stopPropagation()
        setOtherTip(true)
        setSelectedButton(null)
    }
    const handleReset = () => {
        setOtherTip(false)
        setSelectedButton(null)
        setTipAmount(0)
    }

    useEffect(() => {
        let newTip = 0
        try {
            newTip = parseFloat(tipAmount);
            if (tip < 0 || isNaN(tip)) {
                throw new Error('Invalid Tip')
            }
        } catch (error) {
            newTip = 0
            console.error(error.message)
        }
        setTip(newTip)
    }, [tipAmount])

    return (
        <>
            <p className="font-bold textt-[#333333] text-[16px] leading-[25px] mb-4 cursor-pointer" onClick={() => handleReset()}>Add a Tip</p>
            {
                otherTip ?
                    <InputNumber
                        type="number"
                        name="otherTip"
                        className="w-full border rounded focus:outline-none focus:border-solid focus:border-[#186F3D] bg-[#F2F2F2] text-[#333333] py-2 px-2 mt-2"
                        onChange={(e) => setTipAmount(e.target.value)}
                        value={tipAmount}
                    />
                    :
                    <div className="flex justify-between mt-2">
                        {
                            [10, 15, 20, 25].map((tip, index) => {
                                return (
                                    <div className="sm:w-[18%] w-[55px]" key={index} onClick={(e) => handleClickedTip(e, tip)}>
                                        <CardButtons
                                            htmlFor={tip}
                                            className={
                                                `w-full sm:text-base text-[13px] leading-[23px] px-[10px] h-[42px] flex items-center border border-solid border-[#E6E6E6] flex justify-center rounded text-[#333333] capitalize 
                                                ${(selectedButton === tip) ? 'bg-[#186F3D] text-white' : null }`}
                                        >
                                            {`$${tip}`}
                                        </CardButtons>
                                    </div>
                                )
                            })
                        }
                        <div className="sm:w-[18%] w-[55px]" onClick={(e) => handleClickedOther(e)}>
                            <CardButtons
                                htmlFor={'others'}
                                className={
                                    `w-full sm:text-base text-[13px] leading-[23px] px-[10px] h-[42px] flex items-center border border-solid border-[#E6E6E6] flex justify-center rounded text-[#333333] capitalize 
                                    ${(selectedButton === 'others') ? 'bg-[#186F3D] text-white' : null }`} 
                                >
                                {'others'}
                            </CardButtons>
                        </div>
                    </div>
            }
        </>)
}

export default StoreTip