import React from "react";
import { emptyCart, ArrowLeft } from "../../images";

function EmptyCart() {
  return (
    <div>
      <div className="flex flex-col items-center h-[80vh] justify-center mx-auto">
        <img src={emptyCart} alt="cart" />
        <div className="w-[250px] text-center mt-5">
          <h1 className="text-[20px] text-green font-bold">
            Your cart is empty
          </h1>
          <p className="text-[16px] text-gray-400 font-normal mt-2">
            Looks like you have not added anything to your cart yet.
          </p>
        </div>
      </div>
    </div>
  );
}

//FOR TSTING PURPOSE ONLY
//<Route exact path="/cart" element={<EmptyCart />} />

export default EmptyCart;
