import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { postRequest, setOrderForPayment } from '../../../redux/action';
import StoreTip from "../store-tip";
import OrderSummary from "../order-summary";
import CartItem from "../cartItem";
import { Button } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { renderValidUrl, getTokenFromCookie } from '../../../utils/constants';
import { getStoreTotals } from '../../../helpers/cartFunctions';
import { loadStripe } from '@stripe/stripe-js';

const Store = ({ store, handleSubmitOrder }) => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)
    const user = useSelector((state) => state.user);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
    const token = getTokenFromCookie();

    const [tip, setTip] = useState(0.0);
    const [order, setOrder] = useState({
        status: 'Pending',
        paid: 'true',
        store: cart[store]?.id,
        products: Object.keys(cart[store]?.items).map((product) => ({
            productID: cart[store]?.items[product].id,
            amount: cart[store]?.items[product].quantity,
            percentDiscount: cart[store]?.items[product].percentDiscount || 0,
            price: cart[store]?.items[product].price,
            name: cart[store]?.items[product].name
        })),
        ...(user && { customer: user.id }),
        total: 0.0,
        grandTotal: 0.0,
        subTotal: 0.0,
        delivery: 0.0,
        taxes: 0.0,
        tips: 0.0,
    });

    useEffect(() => {
        const totals = getStoreTotals(cart, store);
        setOrder((order) => ({
            ...order,
            ...totals,
            tips: tip,
            grandTotal: totals.total + tip + totals.taxes,
        }));
    }, [cart, store, tip]);

    const [isLoading, setLoading] = useState(false);

    const handleStorePayment = async () => {
        setLoading(true);

        // We obtain our delivery and payment data first
        const [isOrderComplete, { paymentData, orderData }] = await handleSubmitOrder(); // payment Data may be sent to stripe from here

        if (!isOrderComplete) {
            setLoading(false);
            return;
        }
        const finalOrder = { ...order, ...orderData }; // joining delivery details and order details

        try {
            const API_PAYMENTS_ENDPOINT = "/api/payments";
            const headerConfig = token
                ? {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  }
                : {};
            // Ensure that `order` is correctly defined in your code
            const [success, response] = await postRequest(
                API_PAYMENTS_ENDPOINT,
                finalOrder, // This contains the delivery data and order details joined together
                headerConfig
            );

            if (!success || response.status !== 200) {
                console.error(response.error);
                throw new Error("Sorry. Our payment system is down. Please try again later!");
            }

            dispatch(setOrderForPayment({
                ...finalOrder,
                sessionId: response.stripeSession.id
            }))

            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
                sessionId: response.stripeSession.id, // Assuming this is the correct property name
            });
        } catch (error) {
            toast.error(error.message);
        }
        setLoading(false);
    };

    if (!Object.keys(cart).includes(store)) {
        return null;
    }

    return (
        <div>
            <div className="flex items-center py-3 border border-1 border-[#E6E6E6] pl-4 rounded">
                <img
                    src={renderValidUrl(cart[store]?.image)}
                    alt="store-logo"
                    className="mr-2 w-[3-px] h-[30px] sm:w-[40px] sm:h-[40px]"
                />
                <p className="font-bold text-[#333333] text-[13px] leading-[23px] sm:text-[16px] sm:leading-[25px]">
                    {cart[store]?.name}
                </p>
            </div>

            <div>
                {Object.keys(cart[store]?.items).map((product, key) => (
                    <CartItem storeID={store} key={key} data={cart[store]?.items[product]} />
                ))}
            </div>

            <div className="pt-8 border-[#E6E6E6] border-t mt-8">
                <StoreTip setTip={setTip} tip={tip} />

                <div className="border-b border-[#E6E6E6] py-4 mb-4 sm:mb-6">
                    <OrderSummary description="Sub-Total" value={order.subTotal} />
                    <OrderSummary description="Delivery" value={order.delivery} />
                    <OrderSummary description="Taxes" value={order.taxes} />
                    <OrderSummary description="Tip" value={tip} />
                </div>
            </div>

            <div className="flex justify-between mb-4">
                <p className="font-semibold text-[13px] leading-[23px] sm:text-base text-[#4F4F4F]">Total</p>
                <p className="font-bold">
                    <span className="text-[13px] leading-[23px] text-[#25945A]">$</span>
                    <span className="text-[20px] leading-[32px] text-[#186F3D]">{order.grandTotal.toFixed(2)}</span>
                </p>
            </div>

            <Button
                type="submit"
                variant="primary"
                size="full"
                outline="green"
                icon="white"
                className="py-1 mt-4 sm:mb-4"
                loading={isLoading}
                onClick={isLoading ? () => {} : handleStorePayment}
            >
                Pay
            </Button>
        </div>
    );
};

export default Store;
