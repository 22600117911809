import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  AuthScreen,
  EmailVerification,
  NamePhoneVerify,
  WelcomeUser,
  LoggedInProfile,
  ResetPassword,
  CancelModal,
  NoDelivery,
  DeliveryAvailabilty,
  ManageCookies,
  CookiePopup,
  EarlyAccessSuccess,
  EarlyAccessSurvey
} from "./";
import usePortal from "../../hooks/usePortal";

const ModalMap = {
  login: AuthScreen,
  signUp: AuthScreen,
  reset: ResetPassword,
  welcome: WelcomeUser,
  nameVerify: NamePhoneVerify,
  emailVerify: EmailVerification,
  loginProfile: LoggedInProfile,
  cancelModal: CancelModal,
  noDelivery: NoDelivery,
  deliveryAvailability: DeliveryAvailabilty,
  cookiePopup: CookiePopup,
  manageCookies: ManageCookies,
  earlyAccessSurvey: EarlyAccessSurvey,
  earlyAccessSuccess: EarlyAccessSuccess
};

const ModalFactory = () => {
  const  getActiveModalName  = useSelector((state) => state.getActiveModalName);

  const Component = useMemo(() => {
    if (getActiveModalName) return ModalMap[getActiveModalName];
    return null;
  }, [getActiveModalName]);

  const { renderIntoPortal } = usePortal();

  if (!Component) return null;

  return (
    <>
      {renderIntoPortal(
        <Component auth={getActiveModalName === "signUp" ? "signUp" : null} />
      )}
    </>
  );
};

export default ModalFactory;
