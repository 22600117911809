import { useState } from "react";

export const useInput = (initialValue = "", type) => {
  const [value, setValue] = useState(initialValue);

  const handleInputChange = (e) => {
    const newValue =
      type === "text"
        ? e.target.value.replace(/[^A-Za-z ]/g, "")
        : type === "number"
        ? e.target.value.replace(/[^0-9+]/g, "")
        : type === "date"
        ? e.target.value.replace(/[^0-9/]/g, "")
        : type === "text-special"
        ? e.target.value.replace(/[^A-Za-z\s'-]/g, "")
        : e.target.value;
    setValue(newValue);
  };

  return {
    value,
    handleInputChange,
  };
};

export default useInput;
