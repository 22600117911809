import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { activeModal, postRequest } from "../../redux/action";
import InputComponent from "../shared/inputComponent";
import { RadioButton, Button } from "../shared";
import { DocIcon } from "../../images/access";
import { ErrorIcon } from "../../images";
import useInput from "../../hooks/useInput";
import { CITY_OPTIONS } from "../../data";

const EarlyAccessSurvey = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const cityInput = useInput("", "text");
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const SHOPPING_ENUM = ["Very willing", "Somewhat willing", "Not willing"];

  const handleSelect = (option) => {
    setValue("shoppingOptions", option);
    setSelected(option);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const [success, responseData] = await postRequest(
        "/api/early-access-delivery-fee-surveys",
        {
          deliveryFee: Number(data.deliveryFee),
          distance: Number(data.distance),
          shoppingOptions: data.shoppingOptions,
          city: data.city,
          province: data.province
        }
      );

      if (!success || responseData?.error) {
        console.error(responseData?.error?.message);
        toast.error(
          `${
            responseData?.error?.message ||
            "An error occured while submiting your response"
          }`,
          { autoClose: 2000 }
        );
      } else {
        dispatch(activeModal("earlyAccessSuccess"));
      }
    } catch (error) {
      toast.error(`An error occured while submiting your response`, {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center">
      <div className="md:w-[585px] w-full md:py-[24px] max-h-[98vh] inline-block md:px-[32px] overflow-y-auto md:mt-6 p-4 bg-[#FFFFFF] rounded-lg">
        <div className=" flex flex-col justify-center items-center gap-6">
          <DocIcon />
          <div className="flex flex-col justify-center items-center gap-3">
            <h5 className="text-base font-bold md:text-xl text-[#186F3D]">
              Welcome
            </h5>
            <p className="text-[#696969] mt-8 text-center pb-12">
              As we prepare to launch our online African grocery delivery
              platform, we'd love to get your insights to better tailor our
              services to your needs. Your feedback is invaluable to us. Please
              take a moment to answer the following questions.
            </p>
            <div className="dash-line"></div>
          </div>
          <form
            className="flex flex-col items-center justify-center bg-[#FFFFFF1A] w-full my-[30px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full my-4 gap-6">
              <h2 className="text-gray-400 mb-2 font-bold text-sm md:text-base">
                1. Province:
              </h2>
              <label className="text-[#696969] font-normal text-sm md:text-base">
                What province in Canada do you reside ?
              </label>

              <InputComponent
                placeholder="Enter Your Province"
                fieldName="province"
                requiredMessage="Enter Your Province"
                compoundValidation={true}
                register={register}
                errors={errors}
                inputType="Select"
                options={CITY_OPTIONS}
              />
            </div>
            <div className="w-full my-4 gap-6">
              <h2 className="text-gray-400 mb-2 font-bold text-sm md:text-base">
                2. City:
              </h2>
              <label className="text-[#696969] font-normal text-sm md:text-base">
                Your City?
              </label>
              <InputComponent
                type="text"
                placeholder="Enter Your City"
                fieldName="city"
                requiredMessage="EnterYour City"
                patternValue={/^[A-Za-z\s]+$/}
                patternMessage="Only alphabetic characters allowed"
                compoundValidation={true}
                register={register}
                errors={errors}
                value={cityInput.value}
                handleChange={(e) => {
                  cityInput.handleInputChange(e);
                }}
              />
            </div>
            <div className="w-full my-4 gap-6">
              <h2 className="text-gray-400 mb-2 font-bold text-sm md:text-base">
              3. Distance to Nearest African Grocery Store:
              </h2>
              <label className="text-[#696969] font-normal text-sm md:text-base">
                What is the approximate distance in kilometers from your home to
                the nearest African grocery store?
              </label>
              <InputComponent
                type="text"
                fieldName="distance"
                required
                requiredMessage="This is required field"
                patternValue={/^\d+(\.\d{1,2})?$/}
                patternMessage={"Enter distance in km"}
                compoundValidation={true}
                register={register}
                errors={errors}
                className={"mt-2"}
                placeholder={"Enter distance in km"}
              />
            </div>
            <div className="w-full my-4 gap-6">
              <h2 className="text-gray-400 mb-2 font-bold text-sm md:text-base">
              4. Willingness to Shop Online:
              </h2>
              <p className="text-[#696969] font-normal mb-2 text-sm md:text-base">
                How willing are you to purchase your African groceries online?
              </p>
              <Controller
                name="shoppingOptions"
                control={control}
                defaultValue=""
                rules={{ required: "Shopping Options required" }}
                render={({ field }) => (
                  <div>
                    {SHOPPING_ENUM.map((option) => (
                      <div className="mb-2">
                        <RadioButton
                          checked={option === selected}
                          name={option}
                          id={option}
                          handleChange={() => handleSelect(option)}
                          {...field}
                        >
                          <label htmlFor={option} className="text-gray-400">
                            {option}
                          </label>
                        </RadioButton>
                      </div>
                    ))}
                  </div>
                )}
              />
              {errors.shoppingOptions && (
                <div className="flex flex-row gap-2 mt-1 ">
                  <img src={ErrorIcon} alt="errorIcon" />
                  <span className="text-[#FF3B30] text-[10px]">
                    {errors.shoppingOptions.message}
                  </span>
                </div>
              )}
            </div>
            <div className="w-full mt-2 gap-2">
              <h2 className="text-gray-400 mb-2 font-bold text-sm md:text-base">
                5. Maximum Delivery Fee:
              </h2>
              <label className="text-[#696969] font-normal text-sm md:text-base">
                What is the maximum amount you are willing to pay for delivery
                in Canadian Dollars (CAD)?
              </label>
              <InputComponent
                type="text"
                fieldName="deliveryFee"
                required
                requiredMessage="This is required field"
                patternValue={/^\d+(\.\d{1,2})?$/}
                patternMessage={"Enter Fee in canadian dollars"}
                compoundValidation={true}
                register={register}
                errors={errors}
                className={"mt-2"}
                placeholder={"Enter amount in CAD"}
              />
            </div>

            <Button
              className="w-full mt-8 md:mt-14"
              type="submit"
              loading={loading}
              disabled={!isValid}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EarlyAccessSurvey;
