const settings = {
  api: {
    uri: process.env.REACT_APP_API_URL,
  },
  meta: {
    rootUrl: process.env.REACT_APP_PUBLIC_URL,
    title: "Afroshop",
    description: "",
    social: {
      graphic:
        "",
      twitter: "",
    },
  },
  routes: {
    authenticated: {
      pathAfterFailure: "/login",
    },
    public: {
      pathAfterFailure: "/documents",
    },
  },
};

export default settings;