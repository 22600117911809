import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

function usePortal() {
  const [portalContainer, setPortalContainer] = useState(null);

  useEffect(() => {
    // Create a div element to serve as the portal container
    const container = document.createElement('div');
    document.body.appendChild(container);
    setPortalContainer(container);

    // Clean up the portal container when the component unmounts
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  // Function for rendering content into the portal
  function renderIntoPortal(content) {
    if (!portalContainer) return null;
    return createPortal(content, portalContainer);
  }

  return { renderIntoPortal };
}

export default usePortal;
