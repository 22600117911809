import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { activeModal, putRequest, userLogin } from "../../../redux/action";
import { AfroshopLogo, phoneIcon, IconAvatar } from "../../../images";
import { Button } from "../../shared";
import InputComponent from "../../shared/inputComponent";
import { useForm } from "react-hook-form";
import { handleBackdropClick } from "../../../helpers/backdropClick";
import { getTokenFromCookie } from "../../../utils/constants";

function NamePhoneVerify() {
  const [loading, setLoading] = useState(false);
  const user  = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const dispatch = useDispatch();

  const token = getTokenFromCookie();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const [success, responseData] = await putRequest(
        `/api/users/${user.id}`,
        {
          phoneNumber: data.phone,
          firstName: data.firstname,
          lastName: data.lastname,
          confirmed: true,
        },
        token
      );

      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        toast.error(
          `${
            responseData?.error?.message ||
            "An error occured while updating your information"
          }`,
          { autoClose: 2000 }
        );
        dispatch(activeModal("nameVerify"));
      } else {
        dispatch(userLogin(responseData));
        dispatch(activeModal("welcome"));
      }
    } catch (error) {
      toast.error(`An error occured while updating your information`, {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(activeModal(""));
  };

  return (
    <div
      className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center"
      onClick={(e) => handleBackdropClick(e, closeModal)}
    >
      <div className="md:w-[500px] h-[840px] w-full lg:h-[700px]  md:py-[30px] overflow-y-auto md:mt-6 py-7  bg-[#FFFFFF] rounded-lg flex flex-col justify-center">
        <div className=" flex flex-col justify-center items-center gap-6">
          <AfroshopLogo />
          <div className="flex flex-col justify-center items-center gap-3">
            <h5 className="text-base font-bold md:text-xl">One last step!</h5>
            <span className="text-[#CCCCCC] text-xs md:text-base">
              We’ll need your phone to enable faster delivery
            </span>
          </div>

          <form
            className="flex flex-col gap-2 w-[90%] justify-center items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="md:w-[400px] w-full mt-2 gap-2">
              <label className="text-[#B3B3B3] text-xs font-normal">
                First Name
              </label>
              <InputComponent
                type="text"
                fieldName="firstname"
                requiredMessage="This is required field"
                compoundValidation={true}
                register={register}
                errors={errors}
                leftIcon={IconAvatar}
              />
            </div>

            <div className="md:w-[400px] w-full mt-3 gap-2">
              <label className="text-[#B3B3B3] text-xs font-normal">
                Last Name
              </label>
              <InputComponent
                type="text"
                fieldName="lastname"
                requiredMessage="This is required field"
                compoundValidation={true}
                register={register}
                errors={errors}
                leftIcon={IconAvatar}
              />
            </div>

            <div className="md:w-[400px] w-full mt-3 gap-2">
              <label className="text-[#B3B3B3] text-xs font-normal">
                Phone
              </label>
              <InputComponent
                type="tel"
                fieldName="phone"
                requiredMessage="This is required field"
                compoundValidation={true}
                register={register}
                errors={errors}
                leftIcon={phoneIcon}
              />
            </div>
            <Button
              className="mt-7 md:w-[400px] w-[90vw]"
              icon="white"
              type="submit"
              loading={loading}
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NamePhoneVerify;
