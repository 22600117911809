// ConnectButton.js
import React from 'react';
import { googleLogo } from './images';

const ConnectButton = ({ provider }) => {
 

  const handleClick = () => {
    // Redirect to the Strapi authentication endpoint
    window.location.href = `${process.env.REACT_APP_API_URL}/connect/${provider}`;
  };

  return (
    <button className="md:w-[400px] w-[98%] flex flex-row justify-center items-center gap-2 pl-2 py-2 border border-[#CCCCCC] border-solid text-[#333333] text-base rounded lg:flex md:flex" onClick={handleClick}>
       <img src={googleLogo} alt="googleLogo" />
              <span className="text-[16px] text-[#333333] font-normal">

      Connect with {provider}
              </span>
    </button>
  );
};

export default ConnectButton;
