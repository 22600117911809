import React from 'react'
import { AfroshopLogo, PasswordKey } from '../../../../images'
import { useDispatch } from "react-redux";
import { activeModal } from "../../../../redux/action";
import {Button} from "../../../shared";

function ResetSuccesful() {  
  
  const dispatch = useDispatch()

  return (
        <div className='py-[62px] pb-[202px] px-[24px] w-full h-full gap-4 bg-[#FFFFFF] flex flex-col justify-center items-center rounded-lg'>
         <div className='flex flex-col gap-4 items-center'>
         <AfroshopLogo />
         <PasswordKey/>
         </div>
         <div className='w-[342px] flex flex-col justify-center items-center'>
              <h5 className='text-[#333333] text-[16px] md:text-xl font-bold mt-5 mb-1'>
              Password reset successful!
              </h5>
              <span className='text-[#CCCCCC] text-[13px] md:text-base'>Please login to continue</span>
          </div>
        <Button className="mt-3 md:w-[400px] w-[86vw]" icon="white" onClick={() => dispatch(activeModal('login'))}>Log In</Button>
        </div>   
  
  )
}

export default ResetSuccesful